import { DragDropModule } from "@angular/cdk/drag-drop";
import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterModule } from "@angular/router";
import { SurveyResponsesTableComponent } from "../survey-responses/survey-responses-table/survey-responses-table.component";
import { MatTabsModule } from '@angular/material/tabs';
import { ChartsModule } from "ng2-charts";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MomentUtcDateConvertPipe } from './pipes/moment-utc-date-convert.pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from "@angular/material/tooltip";
import { ConfirmDeleteDialogComponent } from './components/confirm-delete-dialog/confirm-delete-dialog.component';


@NgModule({
    declarations: [
        SurveyResponsesTableComponent,
        ErrorPageComponent,
        MomentUtcDateConvertPipe,
        ConfirmDeleteDialogComponent
    ],
    exports: [
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatGridListModule,
        MatCardModule,
        MatMenuModule,
        MatTableModule,
        MatPaginatorModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DragDropModule,
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        MatSortModule,
        MatDialogModule,
        SurveyResponsesTableComponent,
        MatTabsModule,
        ChartsModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatCheckboxModule,
        MomentUtcDateConvertPipe,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTooltipModule,
        ConfirmDeleteDialogComponent
    ],
    imports: [
        LayoutModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatToolbarModule,
        DragDropModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatGridListModule,
        MatCardModule,
        MatMenuModule,
        MatTableModule,
        MatPaginatorModule,
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        MatSortModule,
        MatDialogModule,
        MatTabsModule,
        ChartsModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTooltipModule
    ]
})
export class SharedModule { }
