<div class="view-container" [ngSwitch]="errorCode">
    <ng-template [ngSwitchCase]="401">
        <h2 class="mat-display-2">401 Unauthorized </h2>
        <p class="mat-body"> You need to login! Click <a (click)="login()">Here</a> to login.</p>
    </ng-template>
    <ng-template [ngSwitchCase]="403">
        <h2 class="mat-display-2">403 Forbidden</h2>
        <p class="mat-body">You don't have permission to access the requested resource.</p>
        <p class="mat-body"> Contact your account administrator or ensure that you have the required permissions for the
            request.</p>
    </ng-template>
    <ng-template [ngSwitchCase]="404">
        <h2 class="mat-display-2">404 Not Found</h2>
        <p class="mat-body">The requested resource does not exist.</p>
    </ng-template>
    <ng-template [ngSwitchCase]="500">
        <h2 class="mat-display-2">500 Internal Server Error </h2>
        <p class="mat-body">Oops, Sommething went wrong.</p>
        <p class="mat-body"> The server encountered an internal error or misconfiguration and was unable to complete
            your request.</p>
    </ng-template>
</div>