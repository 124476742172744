import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class CanActivateService {
  currentUser: AccountInfo;
  roles: Array<string>;

  constructor(private authService: MsalService) { }

  getRoles() {
    this.currentUser = this.authService.instance.getAllAccounts()[0];
    this.roles = this.currentUser.idTokenClaims['roles'];
    return this.roles.map(function (role) { return role.toLowerCase(); });
  }
}
