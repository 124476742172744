import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";

@Injectable({
    providedIn: "root",
})

export class HandleErrorService {
    constructor(private _snackBar: MatSnackBar, private _router: Router) { }

    // Handling HTTP Errors using Toaster
    public handleError(err: HttpErrorResponse) {
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            switch (err.status) {
                case 400:
                    errorMessage = "Bad Request.";
                    break;
                case 401:
                    errorMessage = "You need to log in to do this action.";
                    break;
                case 403:
                    errorMessage = "You don't have permission to access the requested resource.";
                    break;
                case 404:
                    errorMessage = "The requested resource does not exist.";
                    break;
                case 412:
                    errorMessage = "Precondition Failed.";
                    break;
                case 500:
                    errorMessage = "Internal Server Error.";
                    break;
                case 503:
                    errorMessage = "The requested service is not available.";
                    break;
                case 422:
                    errorMessage = "Validation Error!";
                    break;
                default:
                    errorMessage = "Something went wrong!";
            }
        }
        this._snackBar.open(errorMessage, 'dismiss', {
            duration: 5000,
            verticalPosition: 'top'
        });
        if (err.error instanceof ErrorEvent) {

        } else {
            console.log(err.status);
            if (err.status === 401 || err.status === 403 || err.status === 404 || err.status == 500) {
                this._router.navigate(['/error', err.status]);
            }
        }
    }

}