<div class="container">
  <div class="row">
    <div class="col-md-12">
      <mat-card>
        <mat-card-content>
          <div>
            <img src="/assets/MIT-US-Logo-Color.png" />
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 col-sm-12">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Surveys</mat-card-title>
          <mat-card-subtitle> This is the area where can add, edit, update, and publish surveys.</mat-card-subtitle>
        </mat-card-header>
        <mat-card-actions>
          <a mat-button [routerLink]="['surveys']">Go</a>
        </mat-card-actions>
      </mat-card>
    </div>
    <div class="col-md-4 col-sm-12">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Sessions</mat-card-title>
          <mat-card-subtitle> This is the area where can add, edit, and update sessions.
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-actions>
          <a mat-button [routerLink]="['sessions']">Go</a>
        </mat-card-actions>
      </mat-card>
    </div>
    <div class="col-md-4 col-sm-12" *ngIf="isSurveyAdmin">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Survey Responses</mat-card-title>
          <mat-card-subtitle> This is the area where can view survey responses and extract the responses
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-actions>
          <a mat-button [routerLink]="['surveys','surveyResponses']">Go</a>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
