import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  errorCode: number;
  constructor(private route: ActivatedRoute, private authService: MsalService) { }

  ngOnInit(): void {
    this.route.params
      .subscribe(_params => {
        this.errorCode = _params['code'];
      });
  }
  login() {
    this.authService.loginRedirect()
      .subscribe(res => {

      });
  }

}
