import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  @Output() logoutEvent: EventEmitter<any> = new EventEmitter();
  @Output() loginEvent: EventEmitter<any> = new EventEmitter();
  @Input() isLoggedIn: boolean;
  currentUser: AccountInfo;
  isSurveyAdmin: boolean;
  isSessionAdmin: boolean;

  sideNavOpen: boolean;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver, private router: Router, private authService: MsalService, private msalBroadcastService: MsalBroadcastService) {

  }
  ngOnInit() {
    this.isSurveyAdmin = false;
    this.isSessionAdmin = false;
    this.sideNavOpen = false;
    this.msalBroadcastService.msalSubject$.subscribe((msg) => {
      if (msg.eventType === 'msal:acquireTokenSuccess' || msg.eventType === 'msal:loginSuccess') {
        this.checkRole();
      }
    });
    this.router.events.subscribe((routerEvent) => {

      if (routerEvent instanceof NavigationEnd) {
        this.sideNavOpen = false;
      }
    });
  }
  togglenav() {
    this.sideNavOpen = !this.sideNavOpen;
  }
  logout(event) {
    this.logoutEvent.emit('');
  }
  login() {
    this.loginEvent.emit('');
  }
  checkRole() {
    this.currentUser = this.authService.instance.getAllAccounts()[0];
    let roles: Array<string> = this.currentUser.idTokenClaims['roles'];
    this.isSurveyAdmin = roles.some(e => e.toLowerCase() === 'portal.admin' || e.toLowerCase() === 'survey.readwrite');
    this.isSessionAdmin = roles.some(e => e.toLowerCase() === 'portal.admin' || e.toLowerCase() === 'schedule.read' || e.toLowerCase() === 'schedule.readwrite');
  }

}
