import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { LocationModel } from '../locations-table/locations-table-datasource';
import { Location } from '@angular/common';
import { LocationsService } from '../locations.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-locations-form',
  templateUrl: './locations-form.component.html',
  styleUrls: ['./locations-form.component.scss']
})
export class LocationsFormComponent implements OnInit {

  @Output() locationSavedEvent: EventEmitter<string> = new EventEmitter<string>();

  @Input() isReadOnly: boolean;
  @Input() locationId: string;
  @Input() sessionId: string;
  @Input() isPopUp: boolean;
  locationData: LocationModel;
  locationForm: FormGroup;
  locations: Array<LocationModel>;
  currentUser: AccountInfo;
  isEditable: boolean;
  isExistingLocation: boolean;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private locationsService: LocationsService,
    private authService: MsalService,
    private location: Location,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.currentUser = this.authService.instance.getAllAccounts()[0];
    this.checkRole();
    this.getLocations();
    this.route.params
      .subscribe(_params => {
        if (_params['locationId']) {
          this.locationsService.getLocation(_params['locationId'])
            .subscribe(locResult => {
              this.locationData = locResult;
              this.setupFormData();
            });
        }
        else {
          this.locationData = {
            'id': null,
            'name': null,
            'streetAddress': null,
            'secondaryStreet': null,
            'city': null,
            'state': null,
            'zipCode': null,
            'room': null,
            'isVirtual': null,
            'sessionLocations': null,
            'schedules': null
          };
          this.setupFormData();
        }
      });
  }
  checkRole() {
    let roles: Array<string> = this.currentUser.idTokenClaims['roles'];
    this.isEditable = roles.some(e => e.toLocaleLowerCase() === 'portal.admin');
  }
  getLocations() {
    if (this.data?.sessionId) {
      this.locationsService.getFilteredLocationListBySessionId(this.data.sessionId)
        .subscribe(result => {
          let locations = result.filter(x => !(this.data.sessionLocations.some(y => y.locationId == x.id)));
          this.locations = locations;
        });
    }
  }
  setupFormData() {
    if (this.locationData.id) {
      this.locationForm = this.fb.group({
        'id': [this.locationData.id],
        'name': [this.locationData.name, Validators.required],
        'streetAddress': [this.locationData.streetAddress, Validators.required],
        'secondaryStreet': [this.locationData.secondaryStreet],
        'city': [this.locationData.city, Validators.required],
        'state': [this.locationData.state, Validators.required],
        'zipCode': [this.locationData.zipCode, Validators.required],
        'room': [this.locationData.room],
        'isVirtual': [this.locationData.isVirtual, Validators.required],
        'sessionLocations': [this.locationData.sessionLocations],
        'schedules': [this.locationData.schedules],
        'tenantId': [this.locationData.tenantId]
      });
    }
    else {
      this.locationForm = this.fb.group({
        'name': [this.locationData.name, Validators.required],
        'streetAddress': [this.locationData.streetAddress, Validators.required],
        'secondaryStreet': [this.locationData.secondaryStreet],
        'city': [this.locationData.city, Validators.required],
        'state': [this.locationData.state, Validators.required],
        'zipCode': [this.locationData.zipCode, Validators.required],
        'room': [this.locationData.room],
        'isVirtual': [this.locationData.isVirtual, Validators.required],
        'sessionLocations': [this.locationData.sessionLocations],
        'schedules': [this.locationData.schedules]
      });
    }
  }
  saveData() {
    if (this.locationData.id) {
      this.locationsService.updateLocation(this.locationForm.value)
        .subscribe(res => {
          this.locationSavedEvent.emit(this.locationData.id);
          this.location.back();
        },
          (error) => {
            this.locationSavedEvent.emit();
          }
        );
    } else {
      this.locationsService.addLocation(this.locationForm.value)
        .subscribe(res => {
          this.locationSavedEvent.emit(res['id']);
        }, (error) => {
          this.locationSavedEvent.emit();
        }
        );
    }
  }
  saveSessionLocationData(sessionLocationId) {
    this.locationSavedEvent.emit(sessionLocationId);
  }
  cancelForm() {
    if (!this.isPopUp)
      this.location.back();
    else
      this.locationSavedEvent.emit();
  }


}
