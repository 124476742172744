import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavBarComponent } from './shared/components/nav-bar/nav-bar.component';
import { HomeComponent } from './home/home.component';
import { MsalInterceptor, MsalBroadcastService, MsalGuard, MsalService, MSAL_INTERCEPTOR_CONFIG, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalRedirectComponent } from '@azure/msal-angular';
import { UnauthenicatiedComponent } from './shared/components/unauthenicatied/unauthenicatied.component';
import { BaseApiEndpointInterceptor } from './core/interceptor/base-api-endpoint.interceptor';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { azureAdMsalModule } from './core/services/auth-service';
import { UnauthorizedComponent } from './shared/components/unauthorized/unauthorized.component';
import { ServerErrorInterceptor } from './core/interceptor/server-error.interceptor';
import { DatePipe } from '@angular/common';
import { SurveyResponsesRecipientDialogComponent } from './survey-responses/survey-responses-recipient-dialog/survey-responses-recipient-dialog.component';
import { CdkColumnDef } from '@angular/cdk/table';


@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    HomeComponent,
    UnauthenicatiedComponent,
    UnauthorizedComponent,
    SurveyResponsesRecipientDialogComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    azureAdMsalModule,
    BrowserAnimationsModule,
    SharedModule,
    AuthModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseApiEndpointInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    DatePipe,
    CdkColumnDef
  ],
  bootstrap: [
    AppComponent,
    MsalRedirectComponent
  ]
})
export class AppModule { }
