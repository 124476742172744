import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { AppComponent } from './app.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { HomeComponent } from './home/home.component';
import { LocationsTableComponent } from './locations/locations-table/locations-table.component';
import { SessionsActivateGuard } from './sessions/sessions-activate.guard';
import { ErrorPageComponent } from './shared/components/error-page/error-page.component';

const routes: Routes = [
    { path: '', component: HomeComponent, pathMatch: 'full' },
    { path: 'error/:code', component: ErrorPageComponent },
    { path: 'logout', component: LogoutComponent },
    { path: 'surveys', canLoad: [MsalGuard], loadChildren: () => import('./surveys/surveys.module').then(m => m.SurveysModule) },
    { path: 'recipients', canLoad: [MsalGuard], loadChildren: () => import('./recipients/recipients.module').then(m => m.RecipientsModule) },
    { path: 'ticketing', loadChildren: () => import(`./ticketing/ticketing.module`).then(m => m.TicketingModule) },
    { path: 'sessions', loadChildren: () => import(`./sessions/sessions.module`).then(m => m.SessionsModule) },
    { path: 'appointments', loadChildren: () => import(`./appointments/appointments.module`).then(m => m.AppointmentsModule) },
    {
      path: 'locations', component: LocationsTableComponent, canActivate: [SessionsActivateGuard], data: {
        role: ['Portal.Admin', 'Schedule.ReadWrite', 'Schedule.Read']
      }
    }
];
const isIframe = window !== window.parent && !window.opener;
@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule],
})
export class AppRoutingModule { }

export const routedComponents = [AppComponent];
