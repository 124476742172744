import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocationDeleteConfirmationModel } from './locations-confirm-delete-dialog/location-delete-confirmation.model';
import { LocationModel } from './locations-table/locations-table-datasource';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {
  baseUrl = environment.resources.filter(r => r.name === 'scheduleApi')[0].resourceUri;
  constructor(private http: HttpClient) { }

  getLocations(): Observable<Array<LocationModel>> {
    return this.http.get<Array<LocationModel>>(`${this.baseUrl}/api/locations`);
  }
  getLocation(locationId: string): Observable<LocationModel> {
    return this.http.get<LocationModel>(`${this.baseUrl}/api/locations/${locationId}`);
  }
  addLocation(location: LocationModel): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}/api/locations`, location);
  }
  updateLocation(location: LocationModel): Observable<string> {
    return this.http.put<string>(`${this.baseUrl}/api/locations/${location.id}`, location);
  }
  getLocationsBySessionId(sessionId: string) {
    return this.http.get<Array<LocationModel>>(`${this.baseUrl}/api/sessions/${sessionId}/locations`);
  }
  getFilteredLocationListBySessionId(sessionId: string) {
    return this.http.get<Array<LocationModel>>(`${this.baseUrl}/api/locations/avaliable/sessions/${sessionId}`);
  }
  deleteLocationBySessionId(sessionLocId: string) {
    return this.http.delete(`${this.baseUrl}/api/SessionLocations/${sessionLocId}`);
  }
  deleteLocationById(id:string){
    return this.http.delete<LocationDeleteConfirmationModel>(`${this.baseUrl}/api/locations/${id}`);
  }
  deleteLocationConfirmById(id:string){
    return this.http.delete<null>(`${this.baseUrl}/api/locations/${id}/confirm`);
  }
}
