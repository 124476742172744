
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { environment } from "src/environments/environment";
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;


/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            
            clientId: environment.azureAdAuthSerrings.clientId,
            authority: 'https://login.microsoftonline.com/' + environment.azureAdAuthSerrings.tenatId,
            redirectUri: environment.azureAdAuthSerrings.redirecturi
        },
        cache: {
            cacheLocation: BrowserCacheLocation.SessionStorage,
            storeAuthStateInCookie: isIE, // set to true for IE 11
        }
    });
}

/**
 * MSAL Angular will automatically retrieve tokens for resources 
 * added to protectedResourceMap. For more info, visit: 
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    environment.resources.forEach(resource => {
        protectedResourceMap.set(resource.resourceUri, resource.resourceScopes);
    });

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return { interactionType: InteractionType.Redirect };
}




export const azureAdMsalModule = MsalModule.forRoot(MSALInstanceFactory(), MSALGuardConfigFactory(), MSALInterceptorConfigFactory());

/**
 * OLD CONFIG
 */

// export const azureAdMsalModule = MsalModule.forRoot({
//     auth: {
//         clientId: environment.azureAdAuthSerrings.clientId, // This is your client ID
//         authority: 'https://login.microsoftonline.com/' + environment.azureAdAuthSerrings.tenatId, // This is your tenant ID
//         redirectUri: environment.azureAdAuthSerrings.redirecturi// This is your redirect URI
//     },
//     cache: {
//         cacheLocation: 'localStorage',
//         storeAuthStateInCookie: isIE, // set to true for IE 11
//     },
// },
//     {
//         popUp: false,
//         consentScopes: [
//             'api://10145298-fe78-4219-a64d-1792b61510a3/Surveys.Admin',
//             'api://10145298-fe78-4219-a64d-1792b61510a3/Surveys.Read.All',
//             'user.read',
//             'openid',
//             'profile'
//         ],
//         unprotectedResources: [],
//         protectedResourceMap: [
//             ['https://graph.microsoft.com/v1.0/me', ['user.read']],
//             ['https://localhost:44301',['api://10145298-fe78-4219-a64d-1792b61510a3/Surveys.Admin','api://10145298-fe78-4219-a64d-1792b61510a3/Surveys.Read.All']]
//             // ['api://10145298-fe78-4219-a64d-1792b61510a3',['Surveys.Read.All']]
//         ],
//         extraQueryParameters: {}
//     });

// export const authParams = {
//     scopes: [
//         'user.read',
//         'api://10145298-fe78-4219-a64d-1792b61510a3/Surveys.Admin',
//         'api://10145298-fe78-4219-a64d-1792b61510a3/Surveys.Read.All',
//         'openid',
//         'profile']
// };