<h1 mat-dialog-title>Confirm Delete</h1>
<div mat-dialog-content>
  Are you sure you want to delete this location?
  <p>The following Sessions will not longer have a relationship to this location:</p>
  <mat-list>
    <mat-list-item *ngFor="let session of confirmationModel.sessions">{{session.name}}</mat-list-item>
  </mat-list>
  <p>The following Schedules will not longer have a relationship to this location:</p>
  <mat-list>
    <mat-list-item *ngFor="let schedule of confirmationModel.schedules">{{schedule.name}}</mat-list-item>
  </mat-list>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="true">Confirm</button>
  <button mat-button mat-dialog-close>Cancel</button>
</div>
