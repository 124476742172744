import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { LocationsFormDialogComponent } from '../locations-form-dialog/locations-form-dialog.component';
import { LocationsService } from '../locations.service';
import { LocationsTableDataSource, LocationModel } from './locations-table-datasource';
import { Session } from 'src/app/sessions/sessions-table/sessions-table-datasource';
import { LocationsConfirmDeleteDialogComponent } from '../locations-confirm-delete-dialog/locations-confirm-delete-dialog.component';

@Component({
  selector: 'app-locations-table',
  templateUrl: './locations-table.component.html',
  styleUrls: ['./locations-table.component.scss']
})
export class LocationsTableComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<LocationModel>;
  @Input() sessionId: string;
  @Output() locationSavedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  locations: Array<LocationModel>;
  dataSource: LocationsTableDataSource;
  currentUser: AccountInfo;
  showAdd: boolean;
  isPortalAdmin = false;
  //sessions: Array<Session>;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */

  displayedColumns = ['name', 'city', 'isVirtual', 'actions'];

  constructor(
    private locationsService: LocationsService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: MsalService,
    public dialog: MatDialog) {

  }

  ngOnInit() {
    this.currentUser = this.authService.instance.getAllAccounts()[0];
    this.checkRole();
    this.getLocationData();
  }

  getLocationData() {
    if (this.sessionId) {
      this.locationsService.getLocationsBySessionId(this.sessionId)
        .subscribe(results => {
          this.locations = results;
          this.setTableData(this.locations);
        });
    }
    else {
      this.locationsService.getLocations().subscribe(results => {
        this.locations = results;
        this.setTableData(this.locations);
      });;
    }
    //let getchoices = this.choicesService.getChoices();
    //forkJoin([getQuestions, getchoices]).subscribe(results => {
    //  console.log(results);
    //  this.questions = results[0];
    //  const choices = results[1];
    //  this.setTableData(choices);
    //});
  }
  setTableData(data: Array<LocationModel>) {
    if (data) {
      this.dataSource = new LocationsTableDataSource(data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.table.dataSource = this.dataSource;
    } else {
      data = [];
      this.dataSource = new LocationsTableDataSource(data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.table.dataSource = this.dataSource;
    }
  }

  checkRole() {
    let roles: Array<string> = this.currentUser.idTokenClaims['roles'];
    this.isPortalAdmin = roles.some(e => e.toLocaleLowerCase() === 'portal.admin');
    this.showAdd = roles.some(e => e.toLocaleLowerCase() === 'portal.admin');
  }
  ngAfterViewInit() {

  }
  refreshLocationData() {
    this.getLocationData();
  }
  openAddLocationPopup(session?: Session) {
    const locationDialogRef = this.dialog.open(LocationsFormDialogComponent, {
      width: '500px',
      height: '600px',
      data: session ? session : {},
      disableClose: true
    });
    locationDialogRef.afterClosed()
      .subscribe(dialogData => {
        if (dialogData) {
          this.locationSavedEvent.emit(true);
        } else
          this.locationSavedEvent.emit(false);
      },
        (error) => {
          this.locationSavedEvent.emit(false);
        });
  }
  deleteLocation(id: string, index: number) {
    const confirmDeleteDialogRef = this.dialog.open(LocationsConfirmDeleteDialogComponent, {
      data: id
    });
    confirmDeleteDialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.locationsService.deleteLocationConfirmById(id)
            .subscribe(res => {
              this.dataSource.data.splice(index, 1);
              this.paginator._changePageSize(this.paginator.pageSize);
            });
        }
        else {

        }
      });
  }

}
