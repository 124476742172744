export const environment = {
  production: false,
  qa: false,
  isMessagingEnabled: true,
  azureAdAuthSerrings: {
    'tenatId': 'c55fab4a-533e-46bb-a450-768de6e9d931',
    'clientId': '95ed0c1b-0569-4222-b17e-cddfeccfdca3',
    'redirecturi': 'https://devsurveyportal.developmeridian.com'
  },
  surveyApiEndPoint: "https://devsurveyportalapi.developmeridian.com",
  resources: [
    {
      name: 'surveyApi:',
      resourceUri: "https://devsurveyportalapi.developmeridian.com",
      resourceScopes: ["api://10145298-fe78-4219-a64d-1792b61510a3/Surveys.Admin", "api://10145298-fe78-4219-a64d-1792b61510a3/Surveys.Read.All"]
    },
    {
      name: 'mitInternalApi',
      resourceUri: "https://devsurveyinternalapi.developmeridian.com",
      resourceScopes: ["api://57622f1a-9905-4254-96a3-13d4e9d765da/Transaction.Create"]
    },
    {
      name: 'scheduleApi',
      resourceUri: "https://devscheduleapi.developmeridian.com",
      resourceScopes: ["api://cd69877c-408e-48de-90d2-dd8a31ffbf9f/ScheduleAccess"]
    },
    {
      name: 'messageApi',
      resourceUri: "https://devmessageapi.developmeridian.com",
      resourceScopes: ["api://a06eb3a5-2369-43bb-807f-52e9fd4527d8/MessageAccess"]
    }
  ],
  currentTenantId: 'freemanHealth',
  sendGridEmail: 'freeman@vaccinerolllout.org',
  emailFromName: 'Freeman Health System',
  twilioNumber: '+12028998694'
};
