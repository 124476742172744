import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-locations-form-dialog',
  templateUrl: './locations-form-dialog.component.html',
  styleUrls: ['./locations-form-dialog.component.scss']
})
export class LocationsFormDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<LocationsFormDialogComponent>) { }

  ngOnInit(): void {
  }

  refreshLocationsData(isRefresh) {
    this.dialogRef.close(isRefresh);
  }
}
