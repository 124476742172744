import { Component, ViewChild } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { SurveyResponsesTableComponent } from '../survey-responses/survey-responses-table/survey-responses-table.component';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { MessagingService } from '../shared/services/messaging.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  @ViewChild('responseList', { static: false }) private surveyResponseList: SurveyResponsesTableComponent;
  /** Based on the screen size, switch from standard to one column per row */
  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return [
          { title: 'Card 1', cols: 1, rows: 1 },
          { title: 'Card 2', cols: 1, rows: 1 },
          { title: 'Card 3', cols: 1, rows: 1 },
          { title: 'Card 4', cols: 1, rows: 1 }
        ];
      }

      return [
        { title: 'Card 1', cols: 2, rows: 1 },
        { title: 'Card 2', cols: 1, rows: 1 },
        { title: 'Card 3', cols: 1, rows: 2 },
        { title: 'Card 4', cols: 1, rows: 1 }
      ];
    })
  );
  loginDisplay = false;
  currentUser: AccountInfo;
  isSurveyAdmin: boolean;

  constructor(private breakpointObserver: BreakpointObserver, private authService: MsalService, private msalBroadcastService: MsalBroadcastService, private messagingService:MessagingService) { }
  ngOnInit() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      })
    this.currentUser = this.authService.instance.getAllAccounts()[0];
    this.checkRole();
  }
  exportResponses() {
    this.surveyResponseList.exportResponses();
  }
  checkRole() {
    let roles: Array<string> = this.currentUser.idTokenClaims['roles'];
    this.isSurveyAdmin = roles.some(e => e.toLowerCase() === 'portal.admin' || e.toLowerCase() === 'survey.readwrite');
  }
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }
  callPhone(){
    this.messagingService.redirectPhoneCall('+18479642730','we are going to redirect you.')
    .subscribe(res =>{
      console.log(res);
    });
  }
}
