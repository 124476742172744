import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { byteCount } from '../../shared/global.functions';
import { environment } from '../../../environments/environment';
import { TransactionLog } from '../models/transaction-log.model';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class TransactionLogService {
  baseUri:string = environment.resources.filter(r => r.name === 'mitInternalApi')[0].resourceUri;
  constructor(private http: HttpClient, private router: Router, private authService: MsalService) { }

  // addTransactionLog(payload: string, requestType: string) {
  //   var internalApiEndPoint = environment.resources.filter(r => r.name === 'mitInternalApi')[0];
  //   var uri = internalApiEndPoint.resourceUri;
  //   var payloadSize = byteCount(payload);

  //   var user = this.authService.instance.getAllAccounts()[0];
  //   var userId = user.homeAccountId.split('.')[0];
  //   var log: TransactionLog = {
  //     applicationId: 'backend-portal',
  //     ipAddress: '',
  //     payLoadSize: payloadSize,
  //     requestType: requestType,
  //     requestedUrl: this.router.url,
  //     tenatId: environment.currentTenat,
  //     userId: userId
  //   };
  //   return this.http.post<any>(uri + `${this.baseUrl}/TransactionLogs`, log);
  // }
  // addTransactionLogWithObject(payloadObject: object, requestType: string) {
  //   let payload = JSON.stringify(payloadObject);
  //   var internalApiEndPoint = environment.resources.filter(r => r.name === 'mitInternalApi')[0];
  //   var uri = internalApiEndPoint.resourceUri;
  //   var payloadSize = byteCount(payload);
  //   var user = this.authService.instance.getAllAccounts()[0];
  //   var userId = user.homeAccountId.split('.')[0];
  //   var log: TransactionLog = {
  //     applicationId: 'backend-portal',
  //     ipAddress: '',
  //     payLoadSize: payloadSize,
  //     requestType: requestType,
  //     requestedUrl: this.router.url,
  //     tenatId: environment.currentTenat,
  //     userId: userId
  //   };
  //   return this.http.post<any>(uri + `${this.baseUrl}/TransactionLogs`, log);
  // }
}
