<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="'over'" [opened]="sideNavOpen">
    <mat-nav-list *ngIf="isLoggedIn">
      <mat-divider></mat-divider>
      <a mat-list-item [routerLink]="['']">Home</a>
      <mat-divider></mat-divider>
      <a mat-list-item [routerLink]="['surveys']">Surveys</a>
      <mat-divider></mat-divider>
      <a mat-list-item *ngIf="isSurveyAdmin" [routerLink]="['surveys','surveyResponses']">Survey Responses</a>
      <mat-divider *ngIf="isSurveyAdmin"></mat-divider>
      <a mat-list-item *ngIf="isSessionAdmin" [routerLink]="['sessions']">Sessions</a>
      <mat-divider *ngIf="isSessionAdmin"></mat-divider>
      <a mat-list-item *ngIf="isSessionAdmin" [routerLink]="['appointments']">Appointments</a>
      <mat-divider *ngIf="isSessionAdmin"></mat-divider>
      <a mat-list-item *ngIf="isSessionAdmin" [routerLink]="['locations']">Locations</a>
      <mat-divider *ngIf="isSessionAdmin"></mat-divider>
      <!-- <a mat-list-item [routerLink]="['ticketing']">Tickets</a>
  <mat-divider *ngIf="isSurveyAdmin"></mat-divider> -->
      <!-- <a mat-list-item [routerLink]="['web-forms']">Web Forms</a>

  <mat-divider></mat-divider> -->
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="mat-typography">
    <mat-toolbar color="primary">
      <button [disabled]="!isLoggedIn" type="button" aria-label="Toggle sidenav" mat-icon-button (click)="togglenav()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>MIT Surveys</span>
      <span class="fill-remaining-space"></span>
      <button  type="button" aria-label="Logout" mat-button (click)="logout($event)">Logout</button>
    </mat-toolbar>
    <ng-template #unauth>
      <app-unauthorized></app-unauthorized>
    </ng-template>
    <router-outlet *ngIf="isLoggedIn;else unauth"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
