import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CanActivateService } from '../shared/can-activate.service';

@Injectable({
  providedIn: 'root'
})
export class SessionsActivateGuard implements CanActivate {
  constructor(private activateService: CanActivateService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url: string = state.url;

    return this.checkRoles(route, url);
  }
  checkRoles(route: ActivatedRouteSnapshot, url: string): true | UrlTree {
    let roles = this.activateService.getRoles();

    if (route.data.role && route.data.role.some(r => roles.indexOf(r.toLowerCase()) >= 0)) { return true; }
  }
}
