import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TransactionLogService } from 'src/app/core/services/transaction-log.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  baseUrl = environment.resources.filter(r => r.name === 'messageApi')[0].resourceUri;

  constructor(private http: HttpClient, private logService: TransactionLogService) { }

  sendEmail(msg: string, to: string, from: string, fromName: string, subject: string) {
    const body = {
      emailMessage: msg,
      sendFromEmailAddress: from,
      sendToEmailAddress: to,
      messageSubject: subject,
      fromName: fromName
    }
    return this.http.post(`${this.baseUrl}/api/email/Send`, body);
  }
  sendText(msg: string, to: string, from: string) {
    const body = {
      textMessage: msg,
      phoneNumber: to,
      clientPhoneNumber: from,
    }
    return this.http.post(`${this.baseUrl}/api/textmessage/SendWithoutUnsubscribe`, body);
  }
  sendVoiceCall(msg: string, to: string, from: string) {
    const body = {
      message: msg,
      clientPhoneNumber: from,
      numberToCall: to,
      numberToForwardCall: '+14175027468'
    }
    return this.http.post(`${this.baseUrl}/api/Voice/makecall`, body);
  }
  redirectPhoneCall(phoneNumber: string, message: string) {
    const body = {
      message: message,
      phoneNumber: phoneNumber
    };
    return this.http.post(`${this.baseUrl}/api/Voice/makecall`, body);
  }
}
