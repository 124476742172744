import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class BaseApiEndpointInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        "Content-Type": "application/json",
        "TenantId": environment.currentTenantId,
        "Cache-Control": 'public;max-age=60'
      }
    });
     return next.handle(req);
  }
}