import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocationsService } from '../locations.service';
import { LocationDeleteConfirmationModel } from './location-delete-confirmation.model';

@Component({
  selector: 'app-locations-confirm-delete-dialog',
  templateUrl: './locations-confirm-delete-dialog.component.html',
  styleUrls: ['./locations-confirm-delete-dialog.component.scss']
})
export class LocationsConfirmDeleteDialogComponent implements OnInit {
  confirmationModel: LocationDeleteConfirmationModel;
  constructor(private locationService: LocationsService,public dialogRef: MatDialogRef<LocationsConfirmDeleteDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: string) { }

  ngOnInit(): void {
    this.locationService.deleteLocationById(this.data)
    .subscribe(result => {
      this.confirmationModel = result;
    });
  }

}
