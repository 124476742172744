<body>
  <app-nav-bar [isLoggedIn]="isLoggedIn" (logoutEvent)="logout($event)" (loginEvent)="login($event)"></app-nav-bar>

</body>

<!-- <mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="'over'" [opened]="sideNavOpen">
    <mat-nav-list *ngIf="isLoggedIn">
      <mat-divider></mat-divider>
      <a mat-list-item [routerLink]="['']">Home</a>
      <mat-divider></mat-divider>
      <a mat-list-item [routerLink]="['surveys']">Surveys</a>
      <mat-divider></mat-divider>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="mat-typography">
    <mat-toolbar color="primary">
      <button [disabled]="!isLoggedIn" type="button" aria-label="Toggle sidenav" mat-icon-button (click)="togglenav()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>MIT Surveys</span>
      <span class="fill-remaining-space"></span>
      <button *ngIf="isLoggedIn" type="button" aria-label="Logout" mat-button (click)="logout($event)">Logout</button>
    </mat-toolbar>
    <ng-template #unauth>
      <button mat-button type="button" (click)="login()">Login</button>
      <app-unauthorized></app-unauthorized>
    </ng-template>
    <router-outlet *ngIf="isLoggedIn;else unauth"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container> -->