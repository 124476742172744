import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';
  acquireTokenFailureSubscription;
  isIframe = false;
  isLoggedIn = false;
  private readonly _destroying$ = new Subject<void>();


  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {


  }
  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.authService.handleRedirectObservable()
      .subscribe((tokenResponse) => {
        var accounts = this.authService.instance.getAllAccounts();

        if (accounts.length > 0) {
          var silentRequest = { ...this.msalGuardConfig.authRequest } as SilentRequest;
          this.msalBroadcastService.inProgress$
            .pipe(
              filter((status: InteractionStatus) => status === InteractionStatus.None),
              takeUntil(this._destroying$)
            )
            .subscribe(() => {
              silentRequest.account = accounts[0];
              this.authService.acquireTokenSilent(silentRequest)
                .subscribe((res) => {
                }, (error) => {
                });
            });

        } else {
          if (!this.isIframe) {
            this.login(null);
          }
        }
        // this.checkAccount();

        /**
         * You can subscribe to MSAL events as shown below. For more info,
         * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
         */
        this.msalBroadcastService.msalSubject$
          .pipe(
            filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
            takeUntil(this._destroying$)
          )
          .subscribe((result) => {
            this.checkAccount();
          });
        // if (this.isLoggedIn) {

        // } else {
        //   this.login(null);
        // }
      });

  }

  checkAccount() {
    var accoutns = this.authService.instance.getAllAccounts();

    this.isLoggedIn = accoutns.length > 0;
  }

  login(event) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe(() => this.checkAccount());
      } else {
        this.authService.loginPopup()
          .subscribe(() => this.checkAccount());
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        //this.msalGuardConfig.authRequest.scopes.push('verified_secondary_email');
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  logout(event) {
    this.authService.logout();
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
