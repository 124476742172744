<div style="margin-bottom: 20px;" *ngIf="data?.sessionId">
  <h2>Location</h2>
  <button mat-raised-button color="default" type="button" [ngClass]="{'btn-primary': isExistingLocation}" (click)="isExistingLocation = true">Use Existing</button>
  <button mat-raised-button color="default" type="button" [ngClass]="{'btn-primary': !isExistingLocation}" (click)="isExistingLocation = false">Create New</button>
</div>
<form *ngIf="locationForm && !isExistingLocation" [formGroup]="locationForm" novalidate (ngSubmit)="saveData()" [ngClass]="{'form-container': !isPopUp}">
  <mat-card class="shipping-card">
    <mat-card-header>
      <mat-card-title>{{locationData.id? 'Edit' : 'Create'}} Location</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput [readonly]="!isEditable" placeholder="Name" formControlName="name">
            <mat-error *ngIf="locationForm.controls['name'].hasError('required')">
              A name is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>Street Address</mat-label>
            <input matInput formControlName="streetAddress" [readonly]="!isEditable" placeholder="Street Address">
            <mat-error *ngIf="locationForm.controls['streetAddress'].hasError('required')">
              A street address is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>Secondary Address</mat-label>
            <input matInput [readonly]="!isEditable" placeholder="Secondary Street" formControlName="secondaryStreet">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>City</mat-label>
            <input matInput [readonly]="!isEditable" placeholder="City" formControlName="city">
            <mat-error *ngIf="locationForm.controls['city'].hasError('required')">
              A city is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>State</mat-label>
            <input matInput [readonly]="!isEditable" placeholder="State" formControlName="state">
            <mat-error *ngIf="locationForm.controls['state'].hasError('required')">
              A state is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>Zip Code</mat-label>
            <input matInput [readonly]="!isEditable" placeholder="Zip Code" formControlName="zipCode">
            <mat-error *ngIf="locationForm.controls['zipCode'].hasError('required')">
              A zipCode is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>Room</mat-label>
            <input matInput [readonly]="!isEditable" placeholder="Room" formControlName="room">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>Is Virtual</mat-label>
            <input matInput [readonly]="!isEditable" placeholder="Is Virtual" formControlName="isVirtual">
            <mat-error *ngIf="locationForm.controls['isVirtual'].hasError('required')">
              A is virtual is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button [disabled]="locationForm.invalid || !isEditable" color="primary" type="submit">Save</button>
      <button mat-raised-button color="primary" type="button" (click)="cancelForm()">Cancel</button>
    </mat-card-actions>
  </mat-card>
</form>
<form (ngSubmit)="saveSessionLocationData(locationId)" *ngIf="data?.sessionId">
  <mat-card *ngIf="isExistingLocation">
    <mat-card-header>
      <mat-card-title>Use Existing Location</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field>
            <mat-label>Location</mat-label>
            <mat-select [(ngModel)]="locationId" name="locationIds" disableOptionCentering>
              <mat-option *ngFor="let loc of locations"
                          [value]="loc.id">
                {{loc.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button [disabled]="!locationId || !isEditable" color="primary" type="submit">Save</button>
      <button mat-raised-button color="primary" type="button" (click)="cancelForm()">Cancel</button>
    </mat-card-actions>
  </mat-card>
</form>
