<!--<div class="spinner-container" *ngIf="!locations">
  <mat-spinner></mat-spinner>
</div>-->
<!--<mat-card>
  <mat-card-header>
    <mat-card-title>
      Locations
      <button mat-icon-button class="more-button" [matMenuTriggerFor]="menu" aria-label="Toggle menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item (click)="openAddLocationPopup()" [disabled]="!showAdd">Add Location</button>
      </mat-menu>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>

  </mat-card-content>
</mat-card>-->
<div class="table-container">
  <table mat-table class="full-width-table" matSort aria-label="Elements">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let row">{{row.name}}</td>
    </ng-container>

    <ng-container matColumnDef="city">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>City</th>
      <td mat-cell *matCellDef="let row">{{row.city}}</td>
    </ng-container>

    <ng-container matColumnDef="isVirtual">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Is Virtual</th>
      <td mat-cell *matCellDef="let row">{{row.isVirtual}}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
      <td mat-cell *matCellDef="let row;;let i = index">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" (click)="$event.stopPropagation()" [xPosition]="'after'" [overlapTrigger]="false">
          <button mat-menu-item [routerLink]="['/sessions', 'locations', row.id]">
            <span>
              <mat-icon>search</mat-icon>
            </span><span>Details</span>
          </button>
          <button *ngIf="isPortalAdmin" mat-menu-item (click)="deleteLocation(row.id, i)">
            <span>
              <mat-icon>clear</mat-icon>
            </span><span>Delete</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="0" [pageSize]="50"
    [pageSizeOptions]="[25, 50, 100, 250]">
  </mat-paginator>
</div>
